.ErrorPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__image {
        width: 100%;
        max-width: 304px;
        margin-bottom: 16px;

        @include vw-medium-down {
            max-width: 200px;
        }
    }

    &__title {
        @extend %heading-medium;

        margin-bottom: 12px;
        color: $color-black;

        @include vw-medium-down {
            margin-bottom: 10px;
        }

        @include vw-xsmall-down {
            margin-bottom: 8px;
        }
    }

    &__description {
        @extend %heading-xsmall;

        margin-bottom: 48px;
        color: $color-gray--light;

        @include vw-medium-down {
            margin-bottom: 40px;
        }

        @include vw-xsmall-down {
            margin-bottom: 32px;
        }
    }
}
